import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import mjson from '../../local-json/Common';
import {Link} from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";

import MetaTags from '../components/MetaTags';
import PriceFAQComponent from './Price/Faq';

function PriceComponent()
{
    let [lurl, setApiurl] = React.useState(null);
    let [post, setPost] = React.useState(null);
    let logurl=[];
    React.useEffect(() => 
    {
        if(Object.keys(mjson).length>0)
        {
            setApiurl(mjson.price_api)
            logurl=mjson.price_api
            
        }
         
        if(logurl!=null)
        {
              
            axios.get(logurl).then((response) => 
            {
                let res=response.data;
                if(res.type=='success')
                {
                    setPost(res.data[0])
                }
            });
        }
        
        AOS.init();
        

    },[]);
    if(post==null) return '';

    AOS.refresh();
    const jumbotronStyle = {
        border: "2px solid var(--primary)", // Custom orange-red border
        borderRadius: "10px",
        padding: "2rem",
        textAlign: "center",
      };
      const pFontSize = {
          fontSize: "19px",
          display: "inline-block",
          borderBottom: "1.5px solid #0000002b",
          paddingBottom: "15px"
      };
  return (
    <main id="main">
      <MetaTags
        title="KalendarDay Pricing | Simple, Transparent Plans"
        description="Discover KalendarDay’s straightforward pricing options. No hidden fees—just simple, transparent plans to help you manage time, budgets, projects, and more effectively."
        image="assets/img/favicon.png"
        name="KalendarDay"
      />
      <section id="pricing" class="pricing">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            
          </div>
          <div class="row">
          <div class="jumbotron jumbotron-fluid" style={jumbotronStyle}>
            <div class="container">
                <h1 class="display-4 text-center">Pricing Page</h1>
                <div className="container text-center mt-5">
                    <p className="fw-bold" style={pFontSize}>
                        The subscription is $5 US per user per month, for all App features.
                    </p>
                    
                    <p className="fw-bold" style={pFontSize}>
                        Access will be limited to the number of subscribed users, regardless of specific names of the users.
                    </p>
                    
                    <p className="fw-bold" style={pFontSize}>
                        Only one data entry and record will be granted for each user.
                    </p>
                    
                    <p className="fw-bold" style={pFontSize}>
                        The change of users does not delete earlier data entered by suspended users.
                    </p>
                    
                    <p className="fw-bold">
                        You can increase or reduce the number of users at any time.
                    </p>
                    <br/>
                    <Link to="/signup" className="btn btn-lg rounded-pill px-4 mt-3" style={{background: "#FF5201", color: "white",padding: "14px 20px"}}>
                    Start 3 Months Free
                    </Link>
                </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" class="faq section">
        {/*<PriceFAQComponent></PriceFAQComponent>*/}
      </section>
    </main>
  );
}
export default PriceComponent;