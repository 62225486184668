import React from "react";
import axios from 'axios';
import ReactDOM from "react-dom";

import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mjson from '../../local-json/Common';
import $ from 'jquery';
import MetaTags from '../components/MetaTags';



const SignupSchema = yup.object().shape({
    
  employees: yup
  .number()
  .typeError('Employee Numbers must be a number') // This handles type errors
  .required('Employee Numbers Required')
  .positive('Employee Numbers must be a positive number') // Optional: enforce positive numbers
  .integer('Employee Numbers must be an integer'), // Optional: enforce integer values
  mobile: yup
  .string()
  .required('Mobile Required')
  .matches(/^[0-9]+$/, 'Mobile must be numeric') // Ensures only numbers
  .min(10, 'Mobile must be at least 10 digits') // Minimum length
  .max(15, 'Mobile must be at most 15 digits'), // Maximum length
  uemail: yup.string().email('Invalid email format').required('Email Required'),
    
  });
  
let hideEmail = function(email) 
{
  return email.replace(/(.{3})(.*)(?=@)/,
    function(gp1, gp2, gp3) { 
      for(let i = 0; i < gp3.length; i++) { 
        gp2+= "*"; 
      } return gp2; 
    });
};


function SignUP()
{
    const [isResponseReceived, setIsResponseReceived] =React.useState(false);
    let [apiUrl, setapiUrl] = React.useState(0);
    let [otpVisible, setOtpVisible] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    let [loginUrl, setLoginUrl] = React.useState(0);
    const [otpEmail, setEmail] = React.useState('');
    const [otpInputValue, setOtpInputValue] = React.useState('');
    React.useEffect(() => 
    {
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('api_link'))
      {
        setapiUrl(mjson.api_link)
      }
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('loginlink'))
      {
        setLoginUrl(mjson.loginlink)
      }
    },[])

    React.useEffect(() => 
    {
      if(Object.keys(mjson).length>0 && mjson.hasOwnProperty('api_link'))
      {
        setapiUrl(mjson.api_link)
      }
    },[])

    const handleOtpChange  = (event) => {
      setOtpInputValue(event.target.value);
    };
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(SignupSchema)
      });
      let { isSubmitting } = formState;
      
      const verifyOTP = async() => {
        try 
        {
          
          let jobj=JSON.parse(localStorage.getItem('reg_response'));
          const newPost = {
            email:jobj.email,
            user_id:jobj.data.id,
            sub_id:jobj.data.sub,
            code:otpInputValue
          };
          setIsDisabled(true);
          const resp = await axios({
            method:'post',
            'url':apiUrl+'mainsite/verify/email',
            responseType:'json',
            headers: 
            {
              'Access-Control-Allow-Origin': '*',
            },
            data:newPost
          })
          .then(function (response) 
          {
            
            console.log(response.data.type)
            if(response.data.type=='success')
            {
              toast.success(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
              });
              localStorage.removeItem('reg_response');
              window.open(loginUrl, "_blank")
              
              const timeId = setTimeout(() => {
                
                navigate('/');
              },2000)
              
            }
            else
            {
              setIsDisabled(false);
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT
               });
            }
          }).catch(function (error){
            console.log(error);
            setIsDisabled(false);
            if(error.response.hasOwnProperty('data') && error.response.data.code=='404')
            {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
               });
               isSubmitting=false;
            }
          });
          
        } catch (err) {
          setIsDisabled(false);
        } finally {
          
        }
      }
      const onSubmit = async(data) => {

        var _d = new Date();
        _d.setMonth(_d.getMonth() + 3);
        
        const newPost = {
            
            email:data.uemail,
            mobile_number:data.mobile,
            employee_count:data.employees,
            date:new Date().toISOString().slice(0, 10),
            end_date:_d.toLocaleDateString()
        };
        try 
        {
          const resp = await axios({
            method:'post',
            'url':apiUrl+'mainsite/register/trail',
            responseType:'json',
            headers: 
            {
              'Access-Control-Allow-Origin': '*',
            },
            data:newPost
          })
          .then(function (response) 
          {
            
            if(response.data.type=='success')
            {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });

                setIsResponseReceived(true);
                response.data.email=newPost.email;
                localStorage.setItem('reg_response', JSON.stringify(response.data));
                isSubmitting=true;

                let jobj=JSON.parse(localStorage.getItem('reg_response'));
                if(jobj!=null)
                {
                  setEmail('Send OTP on this email: '+hideEmail(jobj.email));
                }
                const timeId = setTimeout(() => {
                  isSubmitting=false;
                  
                  //navigate('/verify');
                },5000)
                
                
            }
            else
            {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                isSubmitting=false;
            }
          })
          .catch(function (error) 
          {
            isSubmitting=false;
            toast.error(error.response.data.message[0], {
                position: toast.POSITION.TOP_RIGHT
            });
          });
          
        } 
        catch (err) 
        {
          console.error(err);
        }
      };
      
      
      
    return(
      <>
        <main id="main">

        <MetaTags
                title='Sign Up for KalendarDay | Start Your Free 3-Month Trial'
                description='Join KalendarDay today and enjoy a free 3-month trial! Explore advanced tools for managing time, budgets, projects, and more. No commitment—just better productivity.'
                image='assets/img/favicon.png'
                name='KalendarDay'
            />
          <ToastContainer />
            <section id="signup" className="signup section">
            <div className="container my-5">
              <div className="row">
                {/* Left Section - Text Content */}
                <div className="col-md-6">
                  <h2 className="fw-bold">Start 3 months free</h2>
                  <p>
                    To start your 3 months free trial, please submit your{" "}
                    <strong>email</strong>, <strong>phone number</strong> along with the{" "}
                    <strong>planned number of users</strong>.
                  </p>

                  <p>
                    You will receive <strong>On Time Pass (OTP)</strong>, valid for 24
                    hours. Once received, please enter it and <strong>resubmit</strong>{" "}
                    to have full access for three months, for all requested number of
                    users.
                  </p>

                  <p>
                    After the three months, either you select to continue use of the
                    application or all entered data will be erased.
                  </p>

                  <p>
                    Charges will start at the selling rate per user per month after the
                    trial period is over.
                  </p>
                </div>

                {/* Right Section - Form */}
                <div className="col-md-6">
                {!isResponseReceived && ( 
                    <form onSubmit={handleSubmit(onSubmit)} className="signupForm">
                      <div className="mb-3">
                        <label className="form-label">Email address</label>
                        <input
                          type="email"
                          className="form-control p-3"
                          placeholder="Email address"
                          name="uemail"
                          id="uemail"
                          {...register("uemail")}
                        />
                        {errors.uemail && <p className="p-error">{errors.uemail.message}</p>}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Phone number:</label>
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder="Phone number"
                          name="mobile"
                          id="mobile"
                          {...register("mobile")}
                        />
                        {errors.mobile && <p className="p-error">{errors.mobile.message}</p>}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Planned number of users:</label>
                        <input
                          type="number"
                          className="form-control p-3"
                          placeholder="Planned number of users"
                          name="employees"
                          id="employees"
                          {...register("employees")}
                        />
                        {errors.employees && <p className="p-error">{errors.employees.message}</p>}
                      </div>

                      <div className="text-center">
                          <button 
                          disabled={isSubmitting} 
                          type="submit"
                          style={{background:'rgb(255, 82, 1)',color:'#fff'}}
                          >
                          {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}  
                          SUBMIT
                          </button>
                          
                      </div>
                      </form>
                    )}
                    {isResponseReceived && (
                      <form>
                        <div className="mt-3">
                          {/* <label className="form-label">OTP</label> */}
                          <p>{otpEmail}</p>
                          <p>After otp validation your phone number will be password</p>
                          <input 
                            type="text" 
                            className="form-control p-3" 
                            placeholder="OTP" 
                            name="otp_code"
                            id="otp_code" 
                            value={otpInputValue}
                            onChange={handleOtpChange}
                          />
                        </div>
                        
                        <button
                          type="button"
                          style={{background:'rgb(255, 82, 1)',color:'#fff'}}
                          className="btn btn-lg w-100 mt-2 p-3"
                          onClick={verifyOTP}
                        >
                          SUBMIT
                        </button>
                      </form>
                    )}
                  
                </div>
              </div>
            </div>
            </section>
        </main>
        </>
    );
}
export default SignUP;